div {
  position: relative;
}
.App {
  /*to fit with screen size vw*/
  width: 100%;
  height: 100%;
  scroll-behavior: smooth !important;
  background-color: rgb(244, 241, 241);
}
.topHeader {
  display: grid;
  height: 35%;
  overflow: hidden;
  background-color: #1279e0;
  border-radius: 25px;
  padding: 2rem 2rem;
  margin: 2rem;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  .bioSection{
    justify-self: center;
  }
}

.title {
  height: 30%;
  text-align: center;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 200;
  font-size: 3vw;
  margin: 3rem;
  justify-content: center;
}
.mobileTitle{
    height: 30%;
    text-align: center;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 200;
    justify-content: center;
}

.info-body {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  background-color: rgb(244, 241, 241);
  font-family: Helvetica, Arial, sans-serif;
 
  .skillsetWrapper {
      margin: 9vh 9vw 10vh 9vw;
      color:rgb(19, 16, 16);
      .skill{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        .items{
          margin:1rem;
          padding: 5px 15px 5px 15px;
          border-radius: 20px;
          color: rgb(244, 241, 241);
          background: #3a80c7;
        }
      }
  }
  .p {
    font-family: Helvetica, Arial, sans-serif !important;
    color:rgb(19, 16, 16)!important;
  }
  .experience {
    border-radius: 25px;
    padding: 3rem 3rem 7.5rem 3rem;
    margin: 1rem 2rem 2rem 2rem;
    color: white;
    background: #8dbff2;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    // box-shadow: 1px 1px 2px 0px (var(--joy-shadowChannel, 21 21 21) / var(--joy-shadowOpacity, 0.08));
  }

  .connect {
    margin-left: 15vw;
    margin-right: 15vw;
    margin-top: 9vh;
    margin-bottom: 20vh;
    color:rgb(19, 16, 16);
    background-color: rgb(244, 241, 241);
    .connectIcons {
      display: flex;
      margin-top: 2vh;
      margin-bottom: 2vh;
      .sub-item {
        padding-left: 0.5rem;
      }
    }
    .linkedIn {
      display: flex;
      .linkedIn-btn {
        border: none;
        background-color: transparent;
        font-family: Helvetica, Arial, sans-serif;
        font-size: 17px;
        color: #1976d2;
        padding-left: 8px;
        padding-right: 1vw;
      }
      .linkedIn-btn:hover {
        padding-left: 8px;
        padding-right: 1vw;
        transition: 0.5s;
        color: rgb(195, 26, 26);
        font-size: 17px;
        font-family: Helvetica, Arial, sans-serif;
        text-decoration: none;
        background-color: rgba(25, 118, 210, 0.04);
      }
    }
  }
}
.linkButton {
  border: solid cornflowerblue 2px;
  margin: 18vw;
  text-transform: uppercase;
  text-decoration: none;
}
