.jonah {
    display: grid;
    color: rgb(19, 16, 16);
    margin: 3rem 2rem 0rem 2rem;
    .inputField {
        margin-top: 1rem;
        width: 100wh;
        border-radius: 8px;
        background-color: rgb(69, 68, 68);
    }
    .responseWrapper{
        display: grid;
        .jonahButton{
            margin-top: 1rem;
            padding: 5px 15px 5px 15px;
            border-radius: 20px;
            color:rgb(244, 241, 241);
            background:#3a80c7;
            justify-self: center;
        }
    }
    .response{
        justify-self: center;
        margin: 1rem 0rem 3rem 0rem;
    }

    .jonahBody {
        display: grid;
    }
}
