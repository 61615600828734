.menu {
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    position: sticky;
    top: 0;
    background-color: rgb(244, 241, 241);
    font-family: Helvetica, Arial, sans-serif;
    font-weight: bolder;
    color: black;
    z-index: 2;

    .nav-btn {
        font-family: Helvetica, Arial, sans-serif;
        font-weight: bolder;
        box-shadow: none;
        box-shadow: none;
    }

    .nav-btn:hover {
        transition: 0.5s;
        color: rgb(195, 26, 26);
        font-family: Helvetica, Arial, sans-serif;
    }

    .menuNavbar {
        margin: 1vw 2vw 1vw 2vw;
        text-decoration: none;
        .menuIcon {
                margin-right: .5rem !important;
                color:primary
            }
        .menuIcon:hover {
            color: rgb(195, 26, 26);
        }
        .menuIcon {
                margin-right: .5rem !important;
            }
    }

    .name {
        margin-top: 6px;
        font-size: 1.5rem;
        font-weight: 200;
        justify-self: center;
    }
}
.mobileHeader{
    display: flex;
    justify-content: space-between;
    color: white;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: rgb(244, 241, 241);
    height: 5%;
    .mobileName{
        color:black;
        margin: 6px;
        font-size: 1.5rem;
        font-weight: 200;
    }
}
.mobileMenu {
    display: grid;
    justify-content: space-between;
    padding:1rem;
    border-radius: 0% 20px 20px 0%;
    text-decoration: none;
    position: fixed;
    box-shadow: 1rem 1rem 2rem black;
    background-color: rgb(244, 241, 241);
    color: rgb(244, 241, 241);
    z-index: 2;
    .menuNavbar{
        display: flex;
        text-decoration: none;
        .menuIcon{
            margin-right: .5rem !important;
            color: primary
        }
        .menuIcon:hover{
            color: rgb(195, 26, 26);
        }
        .nav-btn {
            font-family: Helvetica, Arial, sans-serif;
            font-weight: bolder;
            box-shadow: none;
            box-shadow: none;
        }
        
        .nav-btn:hover {
            transition: 0.5s;
            color: rgb(195, 26, 26);
            font-family: Helvetica, Arial, sans-serif;
        }
        }
    }

   
